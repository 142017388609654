import './App.css';
import './fa/styles/all.css';

function App() {
    function openIos() {
        window.open('https://apps.apple.com/de/app/casual-climb-online-rpg/id6451356132', '_blank');
        window.fathom.trackEvent('ios-store-click');
    }

    function openAndroid() {
        window.open('https://play.google.com/store/apps/details?id=com.gruening.games.casual.climb', '_blank');
        window.fathom.trackEvent('android-store-click');
    }

    function openDiscord() {
        window.open('https://discord.gg/t4k2srBKsM', '_blank');
        window.fathom.trackEvent('discord-click');
    }

    return (
        <div>
            <div className={'background'}/>
            <div className="app">
                <img alt={'logo'} src={'./logo.png'} className={'logo'}/>
                <div className={'store-buttons'}>
                    <button className={'store-button apple'} onClick={openIos}><span className={'fa-brands fa-apple'}/> Get it on iOS</button>
                    <button className={'store-button google'} onClick={openAndroid}><span className={'fa-brands fa-google-play'}/> Get it on Android</button>
                    <button className={'store-button discord'} onClick={openDiscord}><span className={'fa-brands fa-discord'}/> Join Discord</button>
                </div>
            </div>
        </div>
    );
}

export default App;
